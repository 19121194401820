import { useGetAccount } from '@/services/api/account';
import { useGetDemoAccount } from '@/services/api/demo/account';
import { Box, Button, ScrollArea, Stack } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React from 'react';
import AccountInfo from '../AccountInfo';
import OverallStats from '../OverallStats';
import DemoReviewList from './DemoReviewList';

export default function DemoOverview() {
  const { data, isLoading: isLoadingUser } = useGetDemoAccount();

  return (
    <>
      <AccountInfo account={data?.data} isLoading={isLoadingUser} isDemo />
      <OverallStats account={data?.data} isLoading={isLoadingUser} />
      <DemoReviewList />
    </>
  );
}
