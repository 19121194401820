import { useAuth } from '@/services/hooks/useAuth';
import AppState from '@/services/state/AppState';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: React.ReactNode; }) => {
  const appState = AppState.useContainer();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && !auth.isAuthenticated && !auth.isLoading && !appState.signupAndLogin.isLoadingToken) {
      auth.signOut(() => {
        appState.resetAll();
        navigate('/login');
      });
    }

    if (auth && !auth.isLoading && !appState.signupAndLogin.isLoadingToken && appState.signupAndLogin.isDemo) {
      navigate('/demo');
    }
  }, [auth?.isAuthenticated, auth?.isLoading, appState.signupAndLogin.isLoadingToken]);

  if (auth?.isAuthenticated && !appState.signupAndLogin.isDemo) {
    return (
      <>
        {children}
      </>
    );
  }

  return null;
};

export default ProtectedRoute;
