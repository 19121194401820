import { Button } from '@mantine/core';
import { StepType } from '@reactour/tour';
import React from 'react';

export const steps: StepType[] = [
  { // step 0
    position: 'center',
    styles: {
      close: base => ({ ...base, display: 'none' }),
      popover: base => ({ ...base, padding: '12px 100px' }),
    },
    selector: '.tour-wrapper',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Let's take a tour!</p>
        <Button bg={'#70C56E'} mx={'auto'} onClick={() => setCurrentStep(1)}>Start</Button>
      </div>
    ),
    stepInteraction: false,
  },
  { // step 1
    stepInteraction: true,
    selector: '.tour-first-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>
          First, you'll review an email that's designed and ready to be sent. This is a real preview of what your email campaigns can look like with Mailberry. Take a moment to go through the content
          and make sure it it’s ready to go.
        </p>
      </div>
    ),
    styles: { close: () => ({ display: 'none' }) },
  },
  { // step 2
    stepInteraction: true,
    selector: '.tour-second-step',
    styles: {
      popover: base => ({ ...base, padding: '0px 24px' }),
      close: () => ({ display: 'none' }),
    },
    position: 'center',
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Hmm, looks like the product description could be improved. Why not ask for a more engaging description that highlights key features? Go ahead and suggest that change now!</p>
        <Button bg={'#70C56E'} mx={'auto'} onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
      </div>
    ),
  },
  {
    selector: '.tour-review-input',
    styles: {
      popover: base => ({ ...base, padding: '0px 24px' }),
      close: () => ({ display: 'none' }),
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Send the comment by tapping the button</p>
      </div>
    ),
  },
  { // step 3
    stepInteraction: true,
    selector: '.tour-third-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Great suggestion! Now, let's submit this for correction. Mailberry will take care of the changes and get it back to you in no time.</p>
      </div>
    ),
    styles: { close: () => ({ display: 'none' }) },
  },
  { // step 4
    // stepInteraction: false,
    selector: '.tour-fourth-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Mailberry has updated your email with the changes you requested. Your email is now ready for review. Take a look and make sure everything looks perfect.</p>
      </div>
    ),
    styles: { close: () => ({ display: 'none' }) },
  },
  { // step 5
    stepInteraction: false,
    selector: '.tour-second-step',
    position: 'center',
    styles: {
      popover: base => ({ ...base, padding: '0px 24px' }),
      close: () => ({ display: 'none' }),
    },
    content: ({ setCurrentStep, currentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>If everything looks good, it's time to approve the email and send it out.</p>
        <Button bg={'#70C56E'} onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
      </div>
    ),
  },
  { // step 6
    selector: '.tour-fifth-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Just click 'Approve' to send this email to your list!</p>
      </div>
    ),
    styles: { close: () => ({ display: 'none' }) },
  },
  { // step 7
    // stepInteraction: false,
    selector: '.tour-wrapper',
    position: 'center',
    content: ({ setCurrentStep, setIsOpen }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Awesome! Your email is on its way. Now, let's check your inbox to see it in action.</p>
        <Button bg={'#70C56E'} onClick={() => setIsOpen(false)}>Close</Button>
      </div>
    ),
    styles: { close: () => ({ display: 'none' }) },
    actionAfter: () => localStorage.setItem('tourCompleted-1', '1'),
  },
  { // step 8
    // stepInteraction: false,
    selector: '.tour-chat',
    styles: { close: () => ({ display: 'none' }) },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Mailberry is here for you! If you ever need help, go to our chat feature right within the app. Go ahead and try it out.</p>
      </div>
    ),
  },
  { // step 9
    // stepInteraction: false,
    selector: '.tour-reports',
    styles: { close: () => ({ display: 'none' }) },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Great job! Now, let’s check out how your email is performing. Mailberry tracks everything for you in real time</p>
        <p>Head over to the 'Reports' section to see detailed insights. Click on the latest email you just sent to dive into the stats</p>
      </div>
    ),
  },
  { // step 10
    // stepInteraction: false,
    selector: '.tour-report-delivery',
    styles: { close: () => ({ display: 'none' }) },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Click on the latest email you just sent to dive into the stats.</p>
      </div>
    ),
  },
  { // step 11
    stepInteraction: false,
    selector: '.tour-report-stats',
    styles: { close: () => ({ display: 'none' }) },
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Here, you can see how many people have opened your email and clicked on the links inside. These metrics help you understand your audience's engagement.</p>
        <Button bg={'#70C56E'} onClick={() => setCurrentStep(13)}>Next</Button>
      </div>
    ),
  },
  { // step 12
    stepInteraction: false,
    position: 'center',
    selector: '.tour-wrapper',
    content: ({ setCurrentStep, setIsOpen }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>
          Congratulations! You've just completed the Mailberry demo and successfully sent your first email. From seamless content creation to real-time performance tracking, Mailberry transforms email
          marketing into an effortless, automated process. It's time to upgrade from your old-school ESP to the next generation of email marketing. Ready to take your email marketing to the next
          level?
        </p>
        <Button
          bg={'#70C56E'}
          onClick={() => {
            setIsOpen(false);
            window.location.href = '/demo';
          }}
        >
          Start Using Mailberry for your business
        </Button>
      </div>
    ),
    styles: { close: () => ({ display: 'none' }) },
    actionAfter: () => localStorage.setItem('tourCompleted-2', '1'),
  },
];
