import { Web } from '@/sharedTypes';
import { genericAuthRequest, RuntimeBackend, useMailberryQuery } from '../base';

export function useGetDemoAccount() {
  return useMailberryQuery<{
    data: { firstName: string; email: string; customerDemoId: string; brandName: string; businessName: string; activeSubscribers: number; averageOpenRate: number; campaingsSent: number; };
  }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/account`, {}, RuntimeBackend);
    },
    options: { refetchInterval: 10000, cacheTime: 10000 },
  });
}
